import { FC, PropsWithChildren as PWC } from 'react'
import { Dialog, DialogBackdrop, DialogPanel, DialogTitle } from '@headlessui/react'
import { twMerge } from 'tailwind-merge'

import IconButton from '~/src/components/generic/IconButton'
import XIcon from '~/src/components/generic/PhosphorIcons/XIcon'
import Button from '~/src/components/generic/Button'

import colors from '~/config/colorsScheme'

type Props = {
  isOpen: boolean
  title: string
  primaryButtonText: string
  isPrimaryButtonDisabled?: boolean
  loading: boolean
  className?: string
  onClose: () => void
  onConfirm?: () => void
}

const BaseDialog: FC<PWC<Props>> = ({
  children,
  isOpen,
  title,
  primaryButtonText,
  loading,
  className,
  onClose,
  onConfirm,
  isPrimaryButtonDisabled = false,
}) => {
  return (
    <Dialog className="relative z-50" open={isOpen} onClose={onClose}>
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-black/25 duration-300 ease-out data-[closed]:opacity-0"
      />
      <div className="fixed inset-0 overflow-y-auto">
        <div className="insert-0 flex min-h-full items-center justify-center p-4 text-center">
          <DialogPanel
            transition
            className={twMerge(
              'rounded bg-white text-left align-middle shadow-xl duration-300 ease-out data-[closed]:scale-95 data-[closed]:opacity-0',
              className
            )}
          >
            <div className="flex items-center border-b border-gray-75 px-5 py-3">
              <DialogTitle className="flex-1 text-center text-base font-bold text-gray-900">
                {title}
              </DialogTitle>
              <IconButton variant="ghost" onClick={onClose} aria-label="Close Dialog">
                <XIcon color={colors.gray[600]} size={20} />
              </IconButton>
            </div>
            <div className="p-6">{children}</div>

            {onConfirm && (
              <div className="mb-4 flex justify-end space-x-4 px-8 py-1">
                <Button variant="secondary" size="md" onClick={onClose}>
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  size="md"
                  loading={loading}
                  onClick={onConfirm}
                  disabled={isPrimaryButtonDisabled}
                >
                  {primaryButtonText}
                </Button>
              </div>
            )}
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  )
}

export default BaseDialog
